import { useHistory } from "react-router";
import { Button } from "../common/catalyst/button";
import React, { useState, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import * as userService from "../services/userService";
import CopyInput from "./CopyInput";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/catalyst/table";
import Recyle from "../images/recyle.svg";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../common/catalyst/dialog";
import { Field, Label } from '../common/catalyst/fieldset'
import { Input } from '../common/catalyst/input'

function TableComponent({ data, handleRow }) {
  let [isOpen, setIsOpen] = useState(false);
  let [quota, setQuota] = useState([]);
  let [index, setIndex] = useState(0);
  const [showHistory, setShowHistory] = useState(false);

  const url = process.env.REACT_APP_API_POINT;

  const history = useHistory();

  const [isVisible, setIsVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [token, setToken] = useState("");

  const handleCloseModal = () => {
    setIsVisible(false);
    setToken("");
    setUserId("");
    setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      const response = await userService.LoginAccess(userId, inputValue);
      const token = response.data.token;
      console.log("Token generated:", token);
  
      //TODO: below line needs to be commented in
      // Open the new tab without appending the token in the URL
      // const newWindow = window.open('https://catalyst.superdashhq.com', '_blank');
      // if (!newWindow) {
      //   console.error("Failed to open new tab");
      //   return;
      // }
  
      // // Ensure the new tab is loaded before sending the token
      // newWindow.onload = () => {
      //   newWindow.postMessage({ token }, 'https://catalyst.superdashhq.com');
      //   console.log("Token sent via postMessage");
      // };
  
      // Fallback: in case onload is not reliable, you might poll or set a timeout.
      // setTimeout(() => {
      //   newWindow.postMessage({ token }, 'https://catalyst.superdashhq.com');
      // }, 1000);
    } catch (error) {
      console.error("Error generating token:", error);
    }
  };

  const apiPoint = process.env.REACT_APP_API_POINT;

  const getClassNames = (item) => {
    return item === "underReview" ? "roundContainer roundContainer--red" : "";
  };

  const getColor = (item) => {
    return item === "ada"
      ? "roundContainer roundContainer--red"
      : item === "scrolls"
      ? "roundContainer roundContainer--green"
      : item === "magna" || item === "turin" || item === "rosetta"
      ? "roundContainer roundContainer--yellow"
      : "";
  };

  const handleToken = (string) => {
    setIsVisible(true);
    setUserId(string);
  };

  console.log("data inside table", data);
  console.log("quota", quota);

  const handleQuotaCheck = (index) => {
    setIsOpen(true);
    setIndex(index);
    console.log("acasa", data[index]);
    setQuota(data[index].usage);
    let allowedValue = data[index].company.voiceSecLimit;
    console.log("......", data[index].company.usage.history);
  };

  return (
    <Table
      striped
      className="[--gutter:--spacing(6)] sm:[--gutter:--spacing(8)]"
    >
      <TableHead>
        <TableRow>
          <TableHeader>Company Name</TableHeader>
          <TableHeader>Email</TableHeader>
          <TableHeader>CompanyId</TableHeader>
          <TableHeader>ProjectId</TableHeader>
          <TableHeader>Package</TableHeader>
          <TableHeader>Health</TableHeader>
          <TableHeader>Quota Refresh</TableHeader>
          <TableHeader>Login</TableHeader>
          <TableHeader>Quota</TableHeader>
          <TableHeader>Configuration</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((project, index) => (
          <TableRow key={`index${project._id}`}>
            <TableCell className="font-medium">
              {project.company.name}
            </TableCell>
            <TableCell>{project.createdBy?project.createdBy.email:""}</TableCell>
            <TableCell>{project.company._id}</TableCell>
            <TableCell>{project._id}</TableCell>
            <TableCell>{project.company.accountType}</TableCell>
            <TableCell className="text-center align-middle">
              {!project.companyhealthStatus ? (
                <span className="inline-block h-3 w-3 rounded-full bg-green-500" />
              ) : (
                <span className="inline-block h-3 w-3 rounded-full bg-red-500" />
              )}
            </TableCell>

            <TableHeader>
              {!project.company.monthlyResetDisabled && (
                <img
                  src={Recyle}
                  alt="Recycle Icon"
                  className="inline-block h-5 w-5"
                />
              )}
            </TableHeader>

            <TableCell>
              <Button onClick={()=>handleToken(project.createdBy._id)} color="zinc">Login</Button>
            </TableCell>
            <TableCell>
              <Button
                type="button"
                onClick={() => handleQuotaCheck(index)}
                color="white"
              >
                Check
              </Button>
            </TableCell>
            <TableCell>
              <Button color="white">Edit</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <Dialog  open={isVisible} onClose={setIsVisible}>
        <DialogBody className="m-5">
          <Field>
            <Label>Passcode</Label>
            <Input name="passcode" type="password" placeholder="xxxxx" onChange={handleInputChange} />
          </Field>
        </DialogBody>
        <DialogActions className="m-5">
          <Button plain onClick={() => setIsVisible(false)}>
            Cancel
          </Button>
          <Button onClick={() => handleButtonClick()}>Login</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpen} onClose={() => setIsVisible(false)} size="3xl">
        <DialogBody>
          {!showHistory ? (
            /* --- Current Usage Table --- */
            <Table bleed compact>
              <TableHead>
                <TableRow>
                  <TableHeader>Field</TableHeader>
                  <TableHeader>Used</TableHeader>
                  <TableHeader>Allowed</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(quota?.current ?? {}).map(
                  ([fieldName, fieldValue]) => {
                    let usedValue = fieldValue;
                    let allowedValue = "N/A";

                    if (fieldName === "callTime") {
                      usedValue = `${(fieldValue / 60).toFixed(2)} minutes`;
                      allowedValue = data[index]?.company?.voiceSecLimit
                        ? `${(data[index].company.voiceSecLimit / 60).toFixed(
                            2
                          )} minutes`
                        : "N/A";
                    }

                    if (fieldName === "questions") {
                      allowedValue = data[index].company.allowedQuestions;
                    }

                    return (
                      <TableRow key={fieldName}>
                        <TableCell className="font-medium">
                          {fieldName}
                        </TableCell>
                        <TableCell className="font-medium">
                          {usedValue}
                        </TableCell>
                        <TableCell className="text-zinc-500">
                          {allowedValue}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          ) : (
            /* --- History Table --- */
            <Table bleed compact>
              <TableHead>
                <TableRow>
                  <TableHeader>Months</TableHeader>
                  <TableHeader>Call Time (minutes)</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[index].company.usage.history.map((timeValue, idx) => (
                  <TableRow key={idx}>
                    <TableCell className="font-medium">{idx}</TableCell>
                    <TableCell className="font-medium">
                      {(timeValue / 60).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setShowHistory(!showHistory)}>
            {showHistory ? "Show Usage" : "Show History"}
          </Button>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Table>
  );
}

export default TableComponent;
