import React,{useEffect} from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { StripePublicKey } from "./config.json";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import "react-toastify/dist/ReactToastify.css";
// import "antd/dist/antd.css";
// import "./scss/App.scss";
// import NotFound from "./pages/NotFound";
// import Home from "./pages/Home";
import ProtectedRoute from "./common/ProtectedRoute";
import Home from "./pages/Home";
import Logout from "./pages/Logout";
import { init } from 'commandbar';
import Dashboard from "./pages/Dashboard";

import * as Headless from '@headlessui/react'

init('d1759647');











// import { login } from "./services/authService";




// const stripePromise = loadStripe(StripePublicKey);

// const tagManagerArgs = {
//   gtmId: 'GTM-W9GKCQT'
// };
// TagManager.initialize(tagManagerArgs);



function App() {

  // useEffect(() => {
  //   const loggedInUserId = '12345'; // example
  //   window.CommandBar.boot(loggedInUserId).then(() => {
  //     // ...
  //   });

  //   return () => {
  //     window.CommandBar.shutdown();
  //   }
  // }, []);


  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: "{{CLIENT_SECRET}}",
  // };
  return (
    <div>
      {/* <Elements stripe={stripePromise}> */}
    
          <ToastContainer />
          <Router>
            <Switch>
              {/* <Route path="/company/:companyId" component={Home} /> */}

              {/* <Route path="/setimage" component={SetImage}/> */}
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <ProtectedRoute path="/dashboard" component={Dashboard} />
              <ProtectedRoute path="/" component={Home} />
              {/* <Route path="/logout" component={Logout} /> */}
              {/* <ProtectedRoute path="/welcome" component={Welcome} /> */}
              {/* <ProtectedRoute path="/setimage/:listingId/:returnTo?" component={SetImage}/> */}
              {/* <Route path="/:streamerName" component={PublicProfile}/> */}
              {/* <Route path="/login" component={Login} /> */}
              {/* <ProtectedRoute path="/" exact component={HomeX} /> */}
              {/* <Route to="/not-found" component={NotFound} />
              <Redirect to="/not-found" /> */}
            </Switch>
          </Router>
     
      {/* </Elements> */}
    </div>
  );
}

//specific ones to generic ones

export default App;
