import { getStatusClassNames } from 'antd/lib/_util/statusUtils';
import React,{ useState, useEffect } from 'react';
import TableComponent from '../common/TableComponent';
import ExtendBar from '../components/ExtendBar';
import * as listingService from "../services/listingService";
import * as userService from "../services/userService";
import { message } from "antd";
import { useHistory} from "react-router";
import Form from "../common/Form";
import Joi from "joi-browser";
import Search from '../common/Search';
import AdvancedSearch from '../common/AdvancedSearch';
import { StackedLayout } from '../common/catalyst/StackedLayout';
import { Avatar } from '../common/catalyst/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../common/catalyst//dropdown'
import { Button } from '../common/catalyst/button'
import { Navbar, NavbarDivider, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from '../common/catalyst/navbar'
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection } from '../common/catalyst/sidebar'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/16/solid'
// import { InboxIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

function Home(props) {


  const navItems = [
    { label: 'Admin', url: '/' },
    { label: 'Monitor', url: '/monitor' },
    { label: 'Third Party', url: '/tp' },
  ]


    const [data, setData] = useState("");
    const [sideBar, setSideBar] = useState(false);
    const [extendData, setExtenddata] = useState();
    const [selection, setSelection] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [listing, setListing] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [userCount, setUserCount] = useState(67);
    const [key, setKey] = useState("projectId");
  
    const [record, setRecord] = useState(100);
    const [type, setType] = useState("any");

  
    const handleSubmit = async () => {
      try {
        const response = await listingService.listingApproval(
          extendData.listingId,
          selection
        );
        if (response.status === 200) {
          message.success("Success");
          setSideBar(false);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400 || ex.response.status <= 500) {
          message.error(ex.response.data);
          return;
        }
      }
    };

    const getTableVisibility =()=>{
      return sideBar?"opacity01":""

  }


  const handleRow =(item)=>{
    setExtenddata(item)
    setSideBar(true)
}


  
    useEffect(() => {
      const fetchData = async () => {
        const { data } = await userService.allUsersCount();
        setUserCount(data.count);
      };
      fetchData();
    }, []);
  
    useEffect(() => {
      const fetchData = async () => {
        const { data } = await listingService.getProjects(record, type);
        console.log(".................data", data)
        setData(data);
      };
      fetchData();
    }, []);








    useEffect(() => {



      const fetchData = async () => {

        if (searchQuery) {
           
          
             if(key==="projectId")
             {
             let workingArray =[...data.projects]
         
            let postsReceived = workingArray.filter((m) =>
              m._id.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchData(postsReceived);
            return; 
          }

          if(key==="companyId")
          {
          let workingArray =[...data.projects]
      
         let postsReceived = workingArray.filter((m) =>
           m.company._id.toLowerCase().includes(searchQuery.toLowerCase())
         );
         setSearchData(postsReceived);
         return; 
       }
            
          }
          



    };
    fetchData();  
        // else if (key === "companyId") {
        //   // filter based on companyId
        //   return item.projects.some(
        //     (project) => project.company._id === searchQuery
        //   );
        // } else {
        //   // invalid key
        //   return false;
        // }
      
    }, [key, searchQuery]);
    
  
    function TeamDropdownMenu() {
      return (
        <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
          <DropdownItem href="/teams/1/settings">
            <Cog8ToothIcon />
            <DropdownLabel>Settings</DropdownLabel>
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem href="/teams/1">
            <Avatar slot="icon" src="/tailwind-logo.svg" />
            <DropdownLabel>Tailwind Labs</DropdownLabel>
          </DropdownItem>
          <DropdownItem href="/teams/2">
            <Avatar slot="icon" initials="WC" className="bg-purple-500 text-white" />
            <DropdownLabel>Workcation</DropdownLabel>
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem href="/teams/create">
            <PlusIcon />
            <DropdownLabel>New team&hellip;</DropdownLabel>
          </DropdownItem>
        </DropdownMenu>
      )
    }

    console.log("............key",key )

    console.log("............searchData", searchData)

    const calculateProjectPercentage = (UserCount, ProjectCount) => {
      const percentage = (ProjectCount / UserCount) * 100;
      return `${percentage.toFixed(2)}%`;
    };

    
      // if(data!=="") {
      //   return (
      //     <div style={{width:"100vw", height:"100vh"}}>
      //       {/* <img src ={`${apiPoint}${item.postImage}`}></img> */}
      //       <div style={{alignItems: "center"}}className='flexrow wideapart'>
      //         <AdvancedSearch setKey={setKey}  setSearchQuery={ setSearchQuery}/>
      //         <div>
      //           {/* <button style={{background:"black", color:"white", width:"10px"}} className='btnn'>100</button>
      //           <button style={{background:"black", color:"white",  width:"10px"}} className='btnn'>200</button> */}

      //           <div style={{background:"black", color:"white"}} className='btnn'> Ration <span>{calculateProjectPercentage(userCount, data.projectCount)}</span></div>
      //           <div style={{background:"black", color:"white"}} className='btnn'>ProjectCount: <span>{data.projectCount}</span></div>
      //           {userCount&& <div style={{background:"black", color:"white"}} className='btnn'>UserCount: <span>{userCount}</span></div>}
      //         </div>
      //       </div>
      //       <div className='flexrow'>
      //         <div className={getTableVisibility()} style={{overflow:"auto"}}>
      //         <TableComponent data={searchQuery===""? data.projects: searchData} handleRow={handleRow}/>   
      //         </div>
      //         <div style={{overflow:"auto"}}>
      //           <ExtendBar selection={selection} setSelection={setSelection} sideBar={sideBar} extendData={extendData} setSideBar={setSideBar} handleSubmit={handleSubmit}/>
      //         </div>
      //       </div>
      //     </div>
      //   );
      // }

      if(data!==""){
      

  return (
    <StackedLayout
      navbar={
        <Navbar>
     <Button>Superdash</Button>
          <NavbarDivider className="max-lg:hidden" />
          <NavbarSection className="max-lg:hidden">
            {navItems.map(({ label, url }) => (
              <NavbarItem key={label} href={url}>
                {label}
              </NavbarItem>
            ))}
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <NavbarItem href="/search" aria-label="Search">
              {/* <MagnifyingGlassIcon /> */}
            </NavbarItem>
            <NavbarItem href="/inbox" aria-label="Inbox">
              {/* <InboxIcon /> */}
            </NavbarItem>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar src="/tailwind-logo.svg" />
                <SidebarLabel>Tailwind Labs</SidebarLabel>
                <ChevronDownIcon />
              </DropdownButton>
              <TeamDropdownMenu />
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {navItems.map(({ label, url }) => (
                <SidebarItem key={label} href={url}>
                  {label}
                </SidebarItem>
              ))}
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
    <TableComponent data={searchQuery===""? data.projects: searchData} handleRow={handleRow}/>   
    </StackedLayout>
  )
      }
    
      
      return null;
    }
    
    export default Home;
    