import { Input, Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { message } from "antd";

function CopyInput({value}) {

  const handleCopy =()=>{
    message.success("Key copied");
  }


console.log("...................ttt", value)

  return (
    <Input.Group compact>
      <Input
        style={{ height: "30px", width: "calc(100% - 50px)" }}
        defaultValue={value}
      />
      <Tooltip title="copy secret key">
      <CopyToClipboard text={value}>
        <Button onClick ={handleCopy} icon={<CopyOutlined />} />
        </CopyToClipboard>

      </Tooltip>
    </Input.Group>
  );
}

export default CopyInput;
